"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFirestore = void 0;
const firestore_1 = require("firebase/firestore");
const firebaseContext_1 = require("../context/firebaseContext");
/**
 * Hook to use Firebase Firestore.
 */
function useFirestore() {
    const firebaseApp = (0, firebaseContext_1.useFirebaseApp)();
    return (0, firestore_1.getFirestore)(firebaseApp);
}
exports.useFirestore = useFirestore;
