"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Payment = void 0;
const jsonUtils_1 = require("benjibooks-shared/utils/jsonUtils");
const dateUtils = __importStar(require("../utils/dateUtils"));
const dateUtils_1 = require("../utils/dateUtils");
class Payment {
    constructor(date, amount) {
        this.date = date;
        this.amount = amount;
    }
    /**
     * Constructs an object of this class from the specified JSON-compatible dictionary object.
     */
    static fromJSON(json) {
        if (typeof json.date !== "string" && !(0, dateUtils_1.isFirebaseTimestamp)(json.date)) {
            throw new Error("No 'date' specified in JSON for Payment.");
        }
        if (typeof json.amount !== "number") {
            throw new Error("No 'amount' specified in JSON for Payment.");
        }
        return new Payment(dateUtils.convertToDate(json.date), json.amount);
    }
    /**
     * Converts this object to JSON-compatible dictionary object.
     * All fields from `this` will be in the returned object except the `undefined` values.
     */
    toJSON() {
        const json = (0, jsonUtils_1.toJSONWithFilters)(this, {
            filterUndefined: true,
        });
        return json;
    }
    /** Returns the payment type of this payment. */
    getPaymentType() {
        return this.amount >= 0 ? Payment.paymentTypes[0] : Payment.paymentTypes[1];
    }
    /** Returns either `1` or `-1` depending on the type of payment. */
    getPaymentMultiplicator(type) {
        switch (type) {
            case Payment.paymentTypes[0]:
                return 1;
            default:
                return -1;
        }
    }
}
exports.Payment = Payment;
Payment.paymentTypes = ["Zahlungseingang", "Rückerstattung"];
