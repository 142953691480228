"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAuth = exports.AuthProvider = void 0;
const auth_1 = require("firebase/auth");
const react_1 = __importStar(require("react"));
const AuthInfo_1 = require("../models/AuthInfo");
const firebaseContext_1 = require("./firebaseContext");
const AuthContext = (0, react_1.createContext)(undefined);
const AuthProvider = (props) => {
    const [authInfo, setAuthInfo] = (0, react_1.useState)(new AuthInfo_1.AuthInfo({ loading: true }));
    const firebaseApp = (0, firebaseContext_1.useFirebaseApp)();
    const firebaseAuth = (0, auth_1.getAuth)(firebaseApp);
    (0, react_1.useEffect)(() => {
        // Triggered when the user signs in or signs out or the id token was refreshed.
        const unsubscribe = firebaseAuth.onIdTokenChanged(async (firebaseUser) => {
            if (firebaseUser) {
                const newAuthInfo = await AuthInfo_1.AuthInfo.fromFirebaseUser(firebaseUser);
                setAuthInfo(newAuthInfo);
            }
            else {
                setAuthInfo(new AuthInfo_1.AuthInfo({ loading: false }));
            }
        });
        return () => unsubscribe();
    }, []);
    return (react_1.default.createElement(AuthContext.Provider, { value: [firebaseAuth, authInfo] }, props.children));
};
exports.AuthProvider = AuthProvider;
/**
 * Returns the context.
 */
function useAuth() {
    const context = (0, react_1.useContext)(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
}
exports.useAuth = useAuth;
