"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAllFromFirestore = exports.createFromFirestore = void 0;
const Booking_1 = require("../Booking");
const CustomerBooking_1 = require("../CustomerBooking");
/** Looks up the reference number and creates corresponding Booking or CustomerBooking. */
function createFromFirestore(doc, config) {
    // Determine if it is a Booking or CustomerBooking by checking the contact field.
    if (Object.prototype.hasOwnProperty.call(doc.data(), "contact")) {
        return CustomerBooking_1.CustomerBooking.fromJSON(doc.id, doc.data(), config);
    }
    else {
        return Booking_1.Booking.fromJSON(doc.id, doc.data(), config);
    }
}
exports.createFromFirestore = createFromFirestore;
/** Looks up the reference number and creates corresponding Booking or CustomerBooking. */
function createAllFromFirestore(docs, config) {
    return docs.map((bookingDoc) => createFromFirestore(bookingDoc, config));
}
exports.createAllFromFirestore = createAllFromFirestore;
