"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toJSONWithFilters = exports.isJSONObjectArray = exports.isJSONArray = exports.isJSONObject = void 0;
/** Custom typeguard function to test if a `JSONValue` is a `JSONObject`. */
function isJSONObject(value) {
    return typeof value === "object" && value !== null && !Array.isArray(value);
}
exports.isJSONObject = isJSONObject;
/** Custom typeguard function to test if a `JSONValue` is a `JSONArray`. */
function isJSONArray(value) {
    return Array.isArray(value);
}
exports.isJSONArray = isJSONArray;
/** Custom typeguard function to test if a `JSONValue` is a `JSONObjectArray`. */
function isJSONObjectArray(value) {
    return isJSONArray(value) && value.every(isJSONObject);
}
exports.isJSONObjectArray = isJSONObjectArray;
/**
 * Converts the spcified object to a JSON object and applies filers.
 *
 * @param obj
 * @param filters
 *    filterUndefined if true, removes all entries with an `undefined` value.
 *    filterFalse if true, removes all entries with a `false` boolean as value.
 *    filterUnderscore if true, removes all entries with a key starting with underscore (`_`).
 *    filterEmptyArray if true, removes all entries with an empty array as value.
 *    filterEmptyString if true, removes all entries with an empty string as value.
 *    filterId if true, removes the `id` entry.
 * @returns the JSON-compatible dictionary object.
 */
function toJSONWithFilters(obj, { filterUndefined = false, filterFalse = false, filterUnderscore = false, filterEmptyArray = false, filterEmptyString = false, filterId = false, }) {
    const json = Object.assign({}, obj);
    // Apply filters.
    Object.entries(json).forEach((entry) => {
        const [key, value] = entry;
        if ((filterUndefined && value === undefined) ||
            (filterFalse && value === false) ||
            (filterUnderscore && key.charAt(0) === "_") ||
            (filterEmptyArray && Array.isArray(value) && value.length === 0) ||
            (filterEmptyString && value === "") ||
            (filterId && key === "id")) {
            delete json[key];
        }
    });
    return json;
}
exports.toJSONWithFilters = toJSONWithFilters;
