"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.User = exports.isUserCustomClaims = exports.isUserDefaultClaims = void 0;
const jsonUtils_1 = require("../utils/jsonUtils");
/** Typeguard function to narrow down to `UserDefaultClaims`. */
function isUserDefaultClaims(obj) {
    if (typeof obj === "object" && obj !== null) {
        const o = obj;
        return (typeof o.uid === "string" &&
            typeof o.email === "string" &&
            typeof o.name === "string");
    }
    return false;
}
exports.isUserDefaultClaims = isUserDefaultClaims;
/** Typeguard function to narrow down to `UserCustomClaims`. */
function isUserCustomClaims(obj) {
    if (typeof obj === "object" && obj !== null) {
        const o = obj;
        return (Array.isArray(o.memberOf) &&
            o.memberOf.every((e) => typeof e === "string") &&
            Array.isArray(o.adminOf) &&
            o.adminOf.every((e) => typeof e === "string") &&
            typeof o.isSuperadmin === "boolean");
    }
    return false;
}
exports.isUserCustomClaims = isUserCustomClaims;
/**
 * The user (in Firestore db or created from Firebase Auth info).
 */
class User {
    /**
     * Creats a user.
     */
    constructor({ id, email, name }) {
        /** List of tenants the user is a member of. */
        this.memberOf = [];
        /** List of tenants the user is an admin of. */
        this.adminOf = [];
        /** True if this is a superadmin user with access to all tenants. */
        this.isSuperadmin = false;
        this.id = id;
        this.email = email;
        this.name = name;
    }
    /**
     * Constructs an object of this class from the specified JSON-compatible dictionary object.
     */
    static fromJSON(id, json) {
        if (typeof json.email !== "string") {
            throw new Error("json does not contain a valid `email` element (must be a string).");
        }
        if (typeof json.name !== "string") {
            throw new Error("json does not contain a valid `name` element (must be a string).");
        }
        const obj = new User({ id, email: json.email, name: json.name });
        Object.assign(obj, json);
        // Parse dates.
        if (json.created) {
            if (typeof json.created !== "string") {
                throw new Error("json does not contain a valid `created` element (must be a string).");
            }
            obj.created = new Date(json.created);
        }
        return obj;
    }
    /**
     * Constructs a User from the specified decodedIdToken.
     *
     * @see https://firebase.google.com/docs/auth/admin/verify-id-tokens
     * @see https://firebase.google.com/docs/reference/admin/node/admin.auth.DecodedIdToken
     */
    static fromDecodedIdToken(decodedToken) {
        const obj = new User({ id: decodedToken.uid, ...decodedToken });
        obj.setCustomClaims(decodedToken);
        return obj;
    }
    /**
     * Converts this object to JSON-compatible dictionary object.
     * All fields from `this` will be in the returned object except the `undefined` values.
     */
    toJSON() {
        const json = (0, jsonUtils_1.toJSONWithFilters)(this, {
            filterUndefined: true,
            filterFalse: true,
            filterEmptyArray: true,
            filterId: true,
        });
        return json;
    }
    /**
     * Set the custom claims.
     */
    setCustomClaims(customClaims) {
        this.memberOf = customClaims.memberOf;
        this.adminOf = customClaims.adminOf;
        this.isSuperadmin = customClaims.isSuperadmin;
    }
    /**
     * Converts the user to `UserCustomClaims`.
     */
    toCustomClaims() {
        return {
            memberOf: this.memberOf,
            adminOf: this.adminOf,
            isSuperadmin: this.isSuperadmin,
        };
    }
    /**
     * Converts the User to a `BookingUser`.
     */
    toBookingUser() {
        return { id: this.id, email: this.email, name: this.name };
    }
    /** Helper method to determine if the user is a member of specified tenant. */
    isMemberOf(tenantId) {
        return this.memberOf.includes(tenantId);
    }
    /** Helper method to determine if the user is an admin of specified tenant. */
    isAdminOf(tenantId) {
        return this.adminOf.includes(tenantId);
    }
}
exports.User = User;
