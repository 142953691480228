"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDiscount = void 0;
const jsonUtils_1 = require("benjibooks-shared/utils/jsonUtils");
/**
 * The `BookingDiscount` is used as a description of discounts in a booking.
 *
 * It has two variants: a percentage or a monetary amount.
 *
 * **Percentage**
 * Example: `{title = "Reason for Discount", percent = 20}`
 *
 * **Monetary Amount**
 * Example: `{title = "Reason for Discount", amount = 50}`
 */
class BookingDiscount {
    constructor() {
        this.title = "";
    }
    static fromConfigDiscount(configDiscount) {
        const obj = new BookingDiscount();
        obj.title = configDiscount.title;
        if (configDiscount.percent !== undefined) {
            obj.percent = configDiscount.percent;
        }
        else if (configDiscount.amount !== undefined) {
            obj.amount = configDiscount.amount;
        }
        return obj;
    }
    static fromJSON(json) {
        const obj = new BookingDiscount();
        Object.assign(obj, json);
        return obj;
    }
    toJSON() {
        const json = (0, jsonUtils_1.toJSONWithFilters)(this, {
            filterUndefined: true,
        });
        return json;
    }
    /** Returns true, a percent is specified. If false, an amount is specified. */
    isPercent() {
        if (this.percent !== undefined) {
            return true;
        }
        else if (this.amount !== undefined) {
            return false;
        }
        else {
            throw new Error("Invalid booking discount: no percent and no amount specified.");
        }
    }
    /** Returns the value of this discount. Can either be a percent or an amount value. */
    getValue() {
        if (this.isPercent()) {
            return this.percent;
        }
        else {
            return this.amount;
        }
    }
    /** The discount calculated with the specified `price`. */
    calc(price) {
        if (this.isPercent()) {
            const discountValue = (price * this.percent) / 100;
            return Math.min(discountValue, price); // Not more than price.
        }
        else {
            const discountValue = this.amount;
            return Math.min(discountValue, price); // Not more than price.
        }
    }
    toString() {
        return (`${this.getValue()}` +
            `${this.isPercent() ? "%" : " CHF"}` +
            (this.title ? ` (${this.title})` : ""));
    }
    isEmpty() {
        return !this.title && !this.getValue();
    }
}
exports.BookingDiscount = BookingDiscount;
