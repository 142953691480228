"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.priceModelCalc = void 0;
/**
 * Calculate the price for the specified quantity using the given `PriceModel`.
 *
 * Prices are rounded to two decimal places.
 *
 * @param priceModel
 * @param quantity
 * @param fullDay if true the full day price is used (if available).
 */
function priceModelCalc(priceModel, quantity, fullDay) {
    let result;
    if (priceModel.length === 0) {
        // No price defined.
        result = 0;
    }
    else if (priceModel.length === 1) {
        // Linear price.
        result =
            (getPriceOrFullDayPrice(priceModel[0], fullDay) /
                priceModel[0].quantity) *
                quantity;
    }
    else {
        // Multiple price levels.
        // Find the index in the sorted price levels.
        const index = priceModel
            .sort((a, b) => a.quantity - b.quantity)
            .findIndex((priceLevel) => quantity <= priceLevel.quantity);
        if (index === 0) {
            // Lower or equal lowest quantity. Return the lowest price.
            result = getPriceOrFullDayPrice(priceModel[0], fullDay);
        }
        else if (index === -1) {
            // Higher or equal to the highest quantity. Return highest price.
            result = getPriceOrFullDayPrice(priceModel[priceModel.length - 1], fullDay);
        }
        else {
            // Somewhere in between. Get the two nearest neighbors and interpolate.
            const lowerIndex = index - 1;
            const lowerQuantity = priceModel[lowerIndex].quantity;
            const lowerPrice = getPriceOrFullDayPrice(priceModel[lowerIndex], fullDay);
            const upperIndex = index;
            const upperQuantity = priceModel[upperIndex].quantity;
            const upperPrice = getPriceOrFullDayPrice(priceModel[upperIndex], fullDay);
            const percentAboveLowQuantity = (quantity - lowerQuantity) / (upperQuantity - lowerQuantity);
            result = (upperPrice - lowerPrice) * percentAboveLowQuantity + lowerPrice;
        }
    }
    return Math.round(result * 100) / 100;
}
exports.priceModelCalc = priceModelCalc;
function getPriceOrFullDayPrice(priceLevel, fullDay) {
    if (fullDay && priceLevel.fullDayPrice != null) {
        return priceLevel.fullDayPrice;
    }
    else {
        return priceLevel.price;
    }
}
