"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddItemDiscountAction = void 0;
const react_1 = __importStar(require("react"));
const BookingDiscount_1 = require("../../../models/BookingDiscount");
const IconDiscount_1 = require("../../icons/IconDiscount");
const DiscountSelectModal_1 = require("../modals/DiscountSelectModal");
const AddItemDiscountAction = (props) => {
    const [modal, setModal] = (0, react_1.useState)();
    function showModal(newModal) {
        setModal(newModal);
    }
    function hideModal() {
        setModal(undefined);
    }
    function handleAddClick() {
        var _a;
        const configDiscounts = (_a = props.item.configItem) === null || _a === void 0 ? void 0 : _a.discounts;
        if (configDiscounts) {
            // Show all predifined discounts as options.
            showModal(react_1.default.createElement(DiscountSelectModal_1.DiscountSelectModal, { className: "booking-item-table-card-modal", configDiscounts: configDiscounts, onClose: hideModal, onDiscountSelected: props.onAddDiscount }));
        }
        else {
            // No matching config item or no discounts. Just add an empty discount with 0%.
            const discount = new BookingDiscount_1.BookingDiscount();
            discount.percent = 0;
            props.onAddDiscount(discount);
        }
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("a", { className: "icon-discount no-print", title: "Rabatt hinzuf\u00FCgen", onClick: handleAddClick },
            react_1.default.createElement(IconDiscount_1.IconDiscount, null)),
        modal));
};
exports.AddItemDiscountAction = AddItemDiscountAction;
