"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomizeItemAction = void 0;
const react_1 = __importStar(require("react"));
const IconArrowDownward_1 = require("../../icons/IconArrowDownward");
const IconArrowForward_1 = require("../../icons/IconArrowForward");
const IconLock_1 = require("../../icons/IconLock");
const IconLockOpen_1 = require("../../icons/IconLockOpen");
const NumericFormatTwoDecimals_1 = require("../../numeric-format/NumericFormatTwoDecimals");
const SimpleModal_1 = require("../../simple-modal/SimpleModal");
const CustomizeItemAction = (props) => {
    const [modal, setModal] = (0, react_1.useState)();
    function showModal(newModal) {
        setModal(newModal);
    }
    function hideModal() {
        setModal(undefined);
    }
    function enableCustomized() {
        props.onCustomizedChange(true);
    }
    function disableCustomized() {
        if (!props.item.configItem) {
            // No matching config item we can attach. Must stay as customized item.
            showModal(react_1.default.createElement(SimpleModal_1.SimpleModal, { className: "booking-item-table-card-modal", title: "\uD83E\uDDD0 Berechnung nicht m\u00F6glich", body: react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement("p", null, "Automatische Berechnung kann nicht aktiviert werden. Ich konnte keinen entsprechenden Artikel finden."),
                    react_1.default.createElement("dl", null,
                        react_1.default.createElement("dt", null, "Titel"),
                        react_1.default.createElement("dd", null,
                            react_1.default.createElement("strong", null, props.item.title ? props.item.title : "---")),
                        react_1.default.createElement("dt", null, "Kategorie"),
                        react_1.default.createElement("dd", null, props.item.type ? props.item.type : "---"),
                        react_1.default.createElement("dt", null, "In Ressource"),
                        react_1.default.createElement("dd", null, props.booking.resourceItem.title))), onClose: hideModal }));
        }
        else {
            // Test against price calculated from config.
            const configPrice = props.item.getConfigPrice(props.booking.isFullDay());
            if (props.item.price === configPrice) {
                // Identical prices. Turn off customized (i.e. switch to calculated mode).
                props.onCustomizedChange(false);
            }
            else {
                // Price or discount is different with calculated price. Ask user to confirm.
                showModal(react_1.default.createElement(SimpleModal_1.SimpleModal, { className: "booking-item-table-card-modal", title: "\uD83E\uDD13 Neuer Preis", body: react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement("p", null, "Ich komme bei meinen Berechnungen auf einen anderen Preis. Willst du den berechneten Preis von mir \u00FCbernehmen?"),
                        react_1.default.createElement("div", { className: "row" },
                            react_1.default.createElement("dl", { className: "col-sm-5" },
                                react_1.default.createElement("dt", null, "Aktuell"),
                                react_1.default.createElement("dd", null,
                                    props.item.quantity,
                                    " ",
                                    props.item.unit,
                                    " =",
                                    react_1.default.createElement("br", null),
                                    react_1.default.createElement("strong", null,
                                        "CHF",
                                        " ",
                                        react_1.default.createElement(NumericFormatTwoDecimals_1.NumericFormatTwoDecimals, { value: props.item.price })))),
                            react_1.default.createElement("div", { className: "col-sm-2" },
                                react_1.default.createElement(IconArrowForward_1.IconArrowForward, { size: "2em", className: "d-none d-sm-inline" }),
                                react_1.default.createElement(IconArrowDownward_1.IconArrowDownward, { size: "2em", className: "d-sm-none" })),
                            react_1.default.createElement("dl", { className: "col-sm-5" },
                                react_1.default.createElement("dt", null, "Neu"),
                                react_1.default.createElement("dd", null,
                                    props.item.quantity,
                                    " ",
                                    props.item.unit,
                                    " =",
                                    react_1.default.createElement("br", null),
                                    react_1.default.createElement("strong", null,
                                        "CHF ",
                                        react_1.default.createElement(NumericFormatTwoDecimals_1.NumericFormatTwoDecimals, { value: configPrice })))))), onClose: hideModal, onOk: () => {
                        props.onCustomizedChange(false);
                        hideModal();
                    } }));
            }
        }
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", { className: "d-flex" }, props.item.customized ? (react_1.default.createElement("a", { className: "icon-lock-open no-print", title: "Automatische Berechnung aktivieren", onClick: disableCustomized },
            react_1.default.createElement(IconLockOpen_1.IconLockOpen, null))) : (react_1.default.createElement("a", { className: "icon-lock no-print", title: "Entkoppeln (keine automatische Berechnung mehr)", onClick: enableCustomized },
            react_1.default.createElement(IconLock_1.IconLock, null)))),
        modal));
};
exports.CustomizeItemAction = CustomizeItemAction;
