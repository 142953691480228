"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfigItem = void 0;
const jsonUtils_1 = require("benjibooks-shared/utils/jsonUtils");
const ConfigDiscount_1 = require("./ConfigDiscount");
/**
 * Booking items defined in the config file.
 */
class ConfigItem {
    /**
     * Constructor.
     */
    constructor(defaultUnits, config) {
        /** The type is used to group things together like "Ausstattung", "Verpflegung" in addons. */
        this.type = "";
        /** The title of the item. */
        this.title = "";
        /** A description in one sentence. */
        this.description = "";
        /** If set to false, the item is hidden in the configurator. */
        this.visible = true;
        /** URL to one ore more images representing this item. */
        this.images = [];
        /** Minimum quantity this item must have. */
        this.minQuantity = 0;
        /** Maximum quantity this item can have.  */
        this.maxQuantity = 1;
        /** The price model. */
        this.priceModel = [];
        this._config = config;
        this.taxRate = config.defaultTaxRate;
        this.units = defaultUnits;
    }
    /**
     * Constructs an object of this class from the specified JSON-compatible dictionary object.
     */
    static fromJSON(json, defaultUnits, config) {
        const obj = new ConfigItem(defaultUnits, config);
        Object.assign(obj, json);
        // Overwrite fields that need converting.
        if ((0, jsonUtils_1.isJSONObjectArray)(json.discounts)) {
            obj.discounts = json.discounts.map((discount) => ConfigDiscount_1.ConfigDiscount.fromJSON(discount));
        }
        return obj;
    }
    /**
     * Converts this object to JSON-compatible dictionary object.
     * All fields from `this` will be in the returned object except the `undefined` values.
     */
    toJSON() {
        const json = (0, jsonUtils_1.toJSONWithFilters)(this, {
            filterUndefined: true,
            filterUnderscore: true, // _config
        });
        // Overwrite fields that need converting.
        if (this.discounts) {
            json.discounts = this.discounts.map((discount) => discount.toJSON());
        }
        return json;
    }
    /** Tries to fiend a discount for the specified discount code. */
    findDiscount(discountCode) {
        if (this.discounts) {
            return this.discounts.find((d) => d.code && d.code.toLowerCase() === discountCode.toLowerCase());
        }
        return undefined;
    }
    getImageUrls() {
        return this.images.map((imageName) => `/${this._config.tenantId}/${imageName}`);
    }
}
exports.ConfigItem = ConfigItem;
