"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteItemAction = void 0;
const react_1 = __importStar(require("react"));
const IconDelete_1 = require("../../icons/IconDelete");
const NumericFormatTwoDecimals_1 = require("../../numeric-format/NumericFormatTwoDecimals");
const SimpleModal_1 = require("../../simple-modal/SimpleModal");
const DeleteItemAction = (props) => {
    const [modal, setModal] = (0, react_1.useState)();
    function showModal(newModal) {
        setModal(newModal);
    }
    function hideModal() {
        setModal(undefined);
    }
    function handleDelete() {
        // Show delete confirmation.
        // No matching config item we can attach. Must stay as customized item.
        showModal(react_1.default.createElement(SimpleModal_1.SimpleModal, { className: "booking-item-table-card-modal", title: "\uD83D\uDE2E Artikel l\u00F6schen?", body: react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement("p", null, "Soll ich den folgenden Artikel l\u00F6schen?"),
                react_1.default.createElement("dl", null,
                    react_1.default.createElement("dt", null, "Titel"),
                    react_1.default.createElement("dd", null, props.item.title ? props.item.title : "---"),
                    react_1.default.createElement("dt", null, "Preis aktuell"),
                    react_1.default.createElement("dd", null,
                        props.item.quantity,
                        " ",
                        props.item.unit,
                        " =",
                        " ",
                        react_1.default.createElement("strong", null,
                            "CHF ",
                            react_1.default.createElement(NumericFormatTwoDecimals_1.NumericFormatTwoDecimals, { value: props.item.price }))))), onClose: hideModal, onOk: () => {
                props.onDelete();
                hideModal();
            } }));
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("a", { className: "icon-delete no-print", title: "Zeile l\u00F6schen", onClick: handleDelete },
            react_1.default.createElement(IconDelete_1.IconDelete, null)),
        modal));
};
exports.DeleteItemAction = DeleteItemAction;
